import React from 'react';
// import sections
import ContactInfo from '../components/sections/ContactInfo';

const ContactUs = () => {

  return (
    <>
      <ContactInfo/>
    </>
  );
}

export default ContactUs;